import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import MapView from "./MapView";
import AboutPage from "./AboutPage";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const App = () => {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route
            path="/locations-i-have-been-to"
            exact
            render={() => {
              return <MapView />;
            }}
          />

          <Route
            path="/"
            exact
            render={() => {
              return <AboutPage />;
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
