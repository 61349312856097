import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"

var CONFIG = require("./config.json");

function get_custom_colors() {
  let arr = ["match", ["get", "description"], "Unnamed", "#4ab4ff", "#ff4400"];
  return arr;
}

const MapView = () => {
  const styles = {
    width: "100%",
    height: "100%",
    // height: "calc(100vh - 80px)",
    position: "absolute",
  };

  // const [map, setMap] = useState(null);
  const [lng, setLongitude] = useState(-104);
  const [lat, setLatitude] = useState(39);
  const [zoom, setZoom] = useState(4);

  const [setting, setSetting] = useState("semantic");
  const [style, setStyle] = useState("dark");
  const [showHeatmapWarning, setShowHeatmapWarning] = useState(true);

  const mapContainer = useRef(null);

  console.log("Longitude: " + lng);
  console.log("Latitude: " + lat);
  console.log("Zoom: " + zoom);

  const handleSettingToggle = () => {
    if (setting === "semantic") {
      if (showHeatmapWarning) {
        var confirmLoad = window.confirm(
          "Due to the large amount of data on the heatmap, loading may take a long period of time. " +
          "Performance may also be severely degraded after loading."
        );
        if (confirmLoad !== true) {
          return;
        }
        setShowHeatmapWarning(false);
      }
      setSetting("full");
    } else {
      setSetting("semantic");
    }
  };

  const handleStyleToggle = () => {
    if (style === "dark") {
      setStyle("satellite");
    } else if (style === "satellite") {
      setStyle("streets");
    } else if (style === "streets") {
      setStyle("dark");
    }
  };

  const renderMapButtonText = () => {
    if (setting === "semantic") {
      return "Heatmap";
    } else {
      return "Simple";
    }
  };

  const renderStyleButtonText = () => {
    if (style === "dark") {
      return "Satellite";
    } else if (style === "satellite") {
      return "Streets";
    } else if (style === "streets") {
      return "Dark";
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = CONFIG.token;

    if (style === "dark") {
      var map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/dark-v10", // stylesheet location
        center: [lng, lat],
        zoom: zoom,
      });
    } else if (style === "satellite") {
      var map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/satellite-streets-v11", // stylesheet location
        center: [lng, lat],
        zoom: zoom,
      });
    } else if (style === "streets") {
      var map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [lng, lat],
        zoom: zoom,
      });
    }

    map.on("move", () => {
      setLongitude(map.getCenter().lng.toFixed(4));
      setLatitude(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    map.on("load", () => {
      // setMap(map);
      map.resize();

      if (setting === "semantic") {
        var semantic_mappings = require("./location_data/semantic_mappings.geojson");
        map.addSource("location_data", {
          type: "geojson",
          data: semantic_mappings,
        });

        map.addLayer({
          id: "location_data",
          source: "location_data",
          type: "circle",
          paint: {
            "circle-radius": {
              base: 2.0,
              stops: [[7, 7]],
            },
            "circle-color": get_custom_colors(),
            "circle-opacity": 0.85,
          },
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.on("mouseenter", "location_data", function (e) {
          map.getCanvas().style.cursor = "pointer";

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;
          var address = e.features[0].properties.address;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup
            .setLngLat(coordinates)
            .setHTML(
              "<h3 id=\"location-name\">" + description + "</h3>" + "<h4 id=\"location-address\">" + address + "</h4>"
            )
            .addTo(map);
        });

        map.on("mouseleave", "location_data", function () {
          map.getCanvas().style.cursor = "";
          popup.remove();
        });
      } else {
        var full_mappings = require("./location_data/full_mappings.geojson");
        map.addSource("location_data", {
          type: "geojson",
          data: full_mappings,
        });

        map.addLayer(
          {
            id: "locations-heat",
            type: "heatmap",
            source: "location_data",
            maxzoom: 16,
            paint: {
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              "heatmap-intensity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                1,
                9,
                3,
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(33,102,172,0)",
                0.2,
                "rgb(103,169,207)",
                0.4,
                "rgb(209,229,240)",
                0.6,
                "rgb(253,219,199)",
                0.8,
                "rgb(239,138,98)",
                1,
                "rgb(178,24,43)",
              ],
              // Adjust the heatmap radius by zoom level
              "heatmap-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                2,
                9,
                20,
              ],
              // Transition from heatmap to circle layer by zoom level
              "heatmap-opacity": {
                stops: [
                  [8, 1.0],
                  [10, 0.8],
                  [12, 0.6],
                  [14, 0.5],
                  [16, 0.4],
                ],
              },
            },
          },
          "waterway-label"
        );

        map.addLayer(
          {
            id: "locations-point",
            type: "circle",
            source: "location_data",
            minzoom: 13,
            paint: {
              // Size circle radius by earthquake magnitude and zoom level
              "circle-radius": 5,
              // Color circle by earthquake magnitude
              "circle-color": "#4ab4ff",
              "circle-stroke-color": "white",
              "circle-stroke-width": 1,
              // Transition from heatmap to circle layer by zoom level
              "circle-opacity": {
                stops: [
                  [14, 0],
                  [15, 0.2],
                  [16, 0.4],
                  [17, 0.6],
                  [18, 0.8],
                  [19, 1],
                ],
              },
            },
          },
          "waterway-label"
        );
      }
    });

    return () => {
      map.remove();
    };
  }, [setting, style]);

  return (
    <>
      <div className="button-wrapper">
        <button className="button-map" onClick={handleSettingToggle}>
          {renderMapButtonText()}
        </button>

        <button className="button-style" onClick={handleStyleToggle}>
          {renderStyleButtonText()}
        </button>
      </div>

      <div ref={(el) => (mapContainer.current = el)} style={styles}></div>
    </>
  );
};

export default MapView;
