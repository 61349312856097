import React, { useState } from "react";
import { Link } from "react-router-dom";
import blackCanyon1 from "./black_canyon.jpg";
import monterey from "./monterey.jpg";
import portrait from "./otherportrait.jpg";
import valorant from "./valorant.png";
import baekjeong from "./baekjeong.png";
import friends from "./IMG_0058.png"
import githubLogo from "./github.png";
import linkedinLogo from "./linkedin.png";
import locationLogo from "./location.png";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

const AboutPage = () => {
  return (
    <>
      <div
        className="welcome-container"
        style={{ height: "100vh", width: "100%" }}
      >
        <div
          className="top-text-wrapper"
          style={{ paddingTop: "calc(50vh - 120px)" }}
        >
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{ fontFamily: "Raleway", fontSize: "42px" }}
          >
            Hey there!
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="1500"
            data-aos-duration="1000"
            style={{
              fontFamily: "Raleway",
              fontSize: "42px",
              marginTop: "10px",
              color: "#3664c7",
            }}
          >
            My name is Derek.
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="3000"
            data-aos-duration="1000"
            style={{
              fontFamily: "Raleway",
              fontSize: "42px",
              marginTop: "10px",
            }}
          >
            Welcome to my page.
          </div>
        </div>
      </div>

      <div style={{ width: "100%", background: "rgb(245,245,245)" }}>
        <Container fluid style={{ width: "85%" }}>
          <Row data-aos="fade-right" data-aos-delay="300" data-aos-duration="500" style={{ height: "33%", paddingTop: "10px", paddingBottom: "10px", borderBottom: "1px solid rgb(200,200,200)" }}>
            <Col md={6}>
              <Image src={portrait} fluid thumbnail />
            </Col>

            <Col className="align-self-center" style={{ fontFamily: "Raleway", background: "white", padding: "1.25rem", border: "1px solid #dee2e6", borderRadius: "0.25rem" }}>
              Born and raised in the San Francisco Bay Area, I am a
              self-certified food/boba connoisseur! :) Oh and I also code
              sometimes... <br />
              <br /> After spending the first eighteen years of my life in the
              Bay Area/California bubble, I switched it up and attended Purdue
              University out in West Lafayette, Indiana to obtain my Bachelors
              in Computer Science. My areas of interest are in Software
              Engineering as well as Computer Vision. Boiler up!
              <br />
              <br />
              After completing my Bachelors in May of 2020, I am now currently
              working in the Bay Area as a Software Engineer full time.
            </Col>
          </Row>

          <Row data-aos="fade-left" data-aos-delay="300" data-aos-duration="500" style={{ height: "33%", paddingTop: "10px", paddingBottom: "10px", borderBottom: "1px solid rgb(200,200,200)" }}>
            <Col md={6}>
              <Image src={monterey} fluid thumbnail />
            </Col>

            <Col className="align-self-center" style={{ fontFamily: "Raleway", background: "white", padding: "1.25rem", border: "1px solid #dee2e6", borderRadius: "0.25rem" }}>
              Outside of doing particularly meaningful work, I also very much
              enjoy sight seeing in the vast outdoors on both a local and
              national scale.
              <br />
              <br />
              Day trips down to Carmel with friends and family are also
              a recurrent outing, especially when heat is a factor in the
              decision to do so. During the winter, ski trips up to the Reno/Tahoe area have
              pretty much become a tradition since childhood. I've also taken a road trip across two-thirds of the country
              from California all the way to Chicago and back.            
            </Col>
          </Row>

          <Row data-aos="fade-right" data-aos-delay="300" data-aos-duration="500" style={{ height: "calc(100% - 66%)", paddingTop: "10px", paddingBottom: "10px" }}>
            <Col md={6}>
              <Image src={friends} fluid thumbnail />
            </Col>

            <Col className="align-self-center" style={{ fontFamily: "Raleway", background: "white", padding: "1.25rem", border: "1px solid #dee2e6", borderRadius: "0.25rem" }}>
              The one thing that this pandemic has allowed for is a once in a lifetime opportunity where friends and family
              could all be back home again at the same time. Something that pretty much has not been particularly realistic since the
              high school times. Who knows when the next time an opportunity like this comes up again once we all scatter like 
              the wind?
              <br />
              <br />
              To the friends and family that have been there with me, I give the utmost gratitude as I could not be where I am
              today without your support. Going forward, I hope our relationships not only remain strong, but grow stronger than they
              ever were before, and that there are many more priceless memories to be shared! :)
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="footer-container"
        style={{ height: "125px", width: "100%" }}
      >
        <div
          className="footer-links-container"
          style={{ height: "100%", width: "100%", display: "inline-block" }}
        >
          <Link to="/locations-i-have-been-to">
            <div
              className="github-link-container"
              style={{
                height: "100%",
                display: "inline-block",
                marginLeft: "20px",
                marginRight: "26px",
              }}
            >
              <img
                src={locationLogo}
                style={{ height: "80px", marginTop: "20px" }}
              />
            </div>
          </Link>

          <a href="https://github.com/derek2041">
            <div
              className="github-link-container"
              style={{
                height: "100%",
                display: "inline-block",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <img
                src={githubLogo}
                style={{ height: "80px", marginTop: "20px" }}
              />
            </div>
          </a>

          <a href="https://www.linkedin.com/in/dareacheshoe">
            <div
              className="github-link-container"
              style={{
                height: "100%",
                display: "inline-block",
                marginLeft: "20px",
                marginRight: "20px",
              }}
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/in/dareacheshoe";
              }}
            >
              <img
                src={linkedinLogo}
                style={{ height: "80px", marginTop: "20px" }}
              />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
